<template>
  <div class="myBill-page">
    <Header seet="4" />
    <el-row type="flex">
      <el-col :span="3"></el-col>
      <el-col style="z-index: 3">
        <div class="breadcrumb-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>账号中心</el-breadcrumb-item>
            <el-breadcrumb-item>账单列表</el-breadcrumb-item>
            <el-breadcrumb-item>账单详情</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="content-box">
          <LeftHurdle style="margin-right: 20px" />
          <div class="box-rights">
            <div class="box-right-top">账单详情</div>
            <div>
              <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="订单编号:">
                  <el-input
                    v-model="form.orderNo"
                    placeholder="请输入订单编号"
                    size="mini"
                    clearable
                    style="width: 200px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="商品名称:">
                  <el-input
                    v-model="form.goodsName"
                    placeholder="请输入商品名称"
                    size="mini"
                    clearable
                    style="width: 200px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="SKUID:">
                  <el-input
                    v-model="form.specId"
                    placeholder="请输入SKUID"
                    size="mini"
                    clearable
                    style="width: 120px;"
                  ></el-input>
                </el-form-item>
                <el-form-item label="订单状态:">
                  <el-select
                    v-model="form.state"
                    placeholder="请选择"
                    size="mini"
                    style="width: 90px !important;"
                    @change="onScreen"
                  >
                    <el-option
                      v-for="item in orderStatusArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="结算状态:">
                  <el-select
                    v-model="form.settleState"
                    placeholder="请选择"
                    size="mini"
                    style="width: 100px !important;"
                    @change="onScreen"
                  >
                    <el-option
                      v-for="item in payStatusArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="结算类型:">
                  <el-select
                    v-model="form.billType"
                    placeholder="请选择"
                    size="mini"
                    style="width: 100px !important;"
                    @change="onScreen"
                  >
                    <el-option
                      v-for="item in settlementTypeArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="下单时间:">
                  <el-date-picker
                    v-model="timeSearch1"
                    :default-time="['00:00:00', '23:59:59']"
                    size="mini"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 200px !important;"
                  >
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="完成时间:">
                  <el-date-picker
                    v-model="timeSearch2"
                    :default-time="['00:00:00', '23:59:59']"
                    size="mini"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 200px !important;"
                  >
                  </el-date-picker>
                </el-form-item>
                <div class="button">
                  <div class="search-btn2" @click="onResetting()">重置</div>
                  <div class="search-btn" @click="getList()">查询</div>
                  <div class="search-btn" style="background-color: #409eff;border-color: #409eff;"
                    :class="{
                        notAllowed: exportLoading
                    }" 
                    @click="onExport()"
                  >
                    <i v-if="exportLoading" class="el-icon-loading"></i>
                    导出
                  </div>
                </div>
              </el-form>
            </div>
            <div class="table-box">
              <el-table
                ref="topUpTable"
                :data="tableData"
                :header-cell-style="{
                  'background-color': '#ccc',
                  color: '#000000d9'
                }"
                style="width: 100%"
                size="mini"
                border
              >
                <el-table-column
                  prop="companyId"
                  label="企业编号"
                  align="center"
                  width="70"
                  fixed="left"
                ></el-table-column>
                <el-table-column
                  prop="companyName"
                  label="企业名称"
                  align="center"
                  min-width="130"
                  fixed="left"
                ></el-table-column>
                <el-table-column
                  prop="orderNo"
                  label="订单编号"
                  align="center"
                  width="150"
                  fixed="left"
                ></el-table-column>
                <el-table-column
                  prop="goodsName"
                  label="商品名称"
                  align="center"
                  min-width="140"
                ></el-table-column>
                <el-table-column
                  prop="specId"
                  label="SKUID"
                  align="center"
                  width="70"
                ></el-table-column>
                <el-table-column
                  prop="specValues"
                  label="商品规格"
                  align="center"
                  min-width="100"
                ></el-table-column>
                <el-table-column
                  prop="billType"
                  label="结算类型"
                  align="center"
                  min-width="100"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.billType, settlementTypeArr) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="orderSubTime"
                  label="下单时间"
                  align="center"
                  width="144"
                ></el-table-column>
                <el-table-column
                  label="订单状态"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.state, orderStatusArr) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="发货时间"
                  align="center"
                  width="144"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.deliveryTime == null ? "-" : scope.row.deliveryTime }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="确认收货时间"
                  align="center"
                  width="144"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.completeTime == null ? "-" : scope.row.completeTime }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="商品类型"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.sourceType, sourceType) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="number"
                  label="销售数量"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.number == null ? "-" : scope.row.number }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="goodsPrice"
                  label="销售单价金额"
                  align="center"
                  width="96"
                ></el-table-column>
                <el-table-column
                  label="优惠券金额"
                  align="center"
                  width="90"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.couponAmount == null ? '-' : scope.row.couponAmount }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="满减金额"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.fullAmount == null ? '-' : scope.row.fullAmount }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="子订单运费金额"
                  align="center"
                  width="116"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.freight == null ? '-' : scope.row.freight }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="实际销售金额"
                  align="center"
                  width="96"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.payAmount == null ? '-' : scope.row.payAmount }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="订单商品金额"
                  align="center"
                  width="96"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.orderGoodsAmount == null ? '-' : scope.row.orderGoodsAmount }}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  label="毛利率"
                  align="center"
                  width="84"
                >
                  <template slot-scope="scope">
                    <span>{{ ((scope.row.goodsPrice - scope.row.onePurchasePrice) / scope.row.goodsPrice * 100).toFixed(2) + '%' }}</span>
                  </template>
                </el-table-column> -->
                <el-table-column
                  label="退货数量"
                  align="center"
                  width="70"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.refundNum == null ? '-' : scope.row.refundNum }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="应退款金额"
                  align="center"
                  width="88"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.refundMoney == null ? '-' : scope.row.refundMoney }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="退款完成时间"
                  align="center"
                  width="144"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.refundTime == null ? '-' : scope.row.refundTime }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="支付方式"
                  align="center"
                  width="90"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.settlementPeriod, payTypeArr) }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="支付时间"
                  align="center"
                  width="144"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.payTime == null ? '-' : scope.row.payTime }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="paymentNo"
                  label="支付流水号"
                  align="center"
                  width="144"
                ></el-table-column>
                <el-table-column
                  prop="currentNumber"
                  label="本次结算数量"
                  align="center"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="currentAmount"
                  label="本次结算金额"
                  align="center"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="billNumber"
                  label="账单内数量"
                  align="center"
                  width="144"
                ></el-table-column>
                <!-- <el-table-column
                  prop="settledNumber"
                  label="已结算数量"
                  align="center"
                  width="90"
                ></el-table-column>
                <el-table-column
                  prop="settledAmount"
                  label="已结算金额"
                  align="center"
                  width="90"
                ></el-table-column> -->
                <el-table-column
                  label="结算状态"
                  align="center"
                  width="80"
                >
                  <template slot-scope="scope">
                    <span>{{ onText(scope.row.settleState, payStatusArr) }}</span>
                  </template>
                </el-table-column>
                <!-- <el-table-column
                  prop="storeName"
                  label="供应商"
                  align="center"
                  width="130"
                ></el-table-column> -->
                <!-- <el-table-column
                  prop="onePurchasePrice"
                  label="采购单价金额"
                  align="center"
                  width="94"
                ></el-table-column> -->
                <!-- <el-table-column
                  prop="purchasePrice"
                  label="成本合计金额"
                  align="center"
                  width="94"
                ></el-table-column> -->
                <!-- <el-table-column
                  prop="refundedCostAmount"
                  label="应退成本金额"
                  align="center"
                  width="94"
                ></el-table-column>
                <el-table-column
                  label="成本税率"
                  align="center"
                  width="88"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row.taxRate * 100 + '%' }}</span>
                  </template>
                </el-table-column> -->
                <el-table-column
                  prop="invoiceCompanyName"
                  label="开票公司名称"
                  align="center"
                  width="106"
                ></el-table-column>
                <el-table-column
                  prop="invoiceTime"
                  label="开票时间"
                  align="center"
                  width="144"
                ></el-table-column>
                <el-table-column
                  prop="invoiceNo"
                  label="开票号码"
                  align="center"
                  width="130"
                ></el-table-column>
                <el-table-column
                  prop="firstLevel"
                  label="一级分类"
                  align="center"
                  width="106"
                ></el-table-column>
                <el-table-column
                  prop="secondLevel"
                  label="二级分类"
                  align="center"
                  width="106"
                ></el-table-column>
                <el-table-column
                  prop="threeLevel"
                  label="三级分类"
                  align="center"
                  width="106"
                ></el-table-column>
                <el-table-column
                  prop="memberName"
                  label="下单人"
                  align="center"
                  width="106"
                ></el-table-column>
                <el-table-column
                  prop="memberPhone"
                  label="下单人电话"
                  align="center"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="customerName"
                  label="收件人"
                  align="center"
                  width="120"
                ></el-table-column>
                <el-table-column
                  prop="phoneNo"
                  label="收件人电话"
                  align="center"
                  width="100"
                ></el-table-column>
                <el-table-column
                  prop="address"
                  label="收货地址"
                  align="center"
                  width="150"
                ></el-table-column>
              </el-table>
              <div class="tablePage">
                <el-pagination
                  background
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 50, 100]"
                  :page-size="pageSize"
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="3"></el-col>
    </el-row>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Layout/header.vue'
import Footer from '@/components/Layout/footer.vue'
import LeftHurdle from '@/components/Layout/leftHurdle.vue'
import {
  queryBillInfo,
  exportBillInfo
} from '@/api/order/order'
export default {
  components: {
    Header,
    Footer,
    LeftHurdle
  },
  data() {
    return {
      exportLoading: false,
      orderStatusArr: [ // 订单状态
        {
          value: undefined,
          label: '全部'
        }, {
          value: 1,
          label: '待确认'
        }, {
          value: 2,
          label: '待发货'
        }, {
          value: 3,
          label: '待收货'
        }, {
          value: 4,
          label: '退货退款中'
        }, {
          value: 5,
          label: '已完成'
        }, {
          value: 6,
          label: '已退款'
        // }, {
        //   value: 7,
        //   label: '已取消'
        }
      ],
      sourceType: [ // 商品类型
        {
          value: 0,
          label: '自营'
        }, {
          value: 1,
          label: '京东'
        }, {
          value: 2,
          label: '震坤行'
        }
      ],
      payTypeArr: [ // 支付方式
        {
          value: 2,
          label: '按周结算'
        }, {
          value: 3,
          label: '按月结算'
        }, {
          value: 4,
          label: '按季度结算'
        }, {
          value: 5,
          label: '预付款'
        }
      ],
      payStatusArr: [ // 结算状态
        {
          value: undefined,
          label: '全部'
        }, {
          value: 0,
          label: '未结算'
        }, {
          value: 1,
          label: '部分结算'
        }, {
          value: 2,
          label: '已结算'
        }
      ],
      settlementTypeArr: [ // 结算类型
        {
          value: 0,
          label: '支付结算'
        }, {
          value: 1,
          label: '售后结算'
        }
      ],
      onExportBtn: false,
      formData: {}, // 弹窗需要的数据
      timeSearch1: [], // 时间搜索 下单时间
      timeSearch2: [], // 时间搜索 订单完成时间
      form: { // 搜索容器
        orderNo: '', // 订单编号
        goodsName: '', // 公司名称
        specId: '', // 商品规格id
        state: '', // 订单状态：1-待付款 2-待发货 3-待收货 4-退货退款中(后端注意:这里和订单表不太一样) 5-已完成 6-已退款 7-取消
        settleState: '' // 支付/结算状态
      },
      tableData: [], // 列表数据
      currentPage: 1, // 当前页
      pageSize: 20, // 页条数
      total: 0 // 总页数
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        billNo: this.$route.query.billNo,
        isUsers: 1,
        ...this.form
      }
      if (this.timeSearch1 != null && this.timeSearch1.length > 0) {
        data.orderSubStartTime = this.timeSearch1[0]
        data.orderCompleteStartTime = this.timeSearch1[1]
      }
      if (this.timeSearch2 != null && this.timeSearch2.length > 0) {
        data.receiveStartTime = this.timeSearch2[0]
        data.receiveEndTime = this.timeSearch2[1]
      }
      queryBillInfo(data).then((res) => {
        if (res.code == '00000') {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.$message({ message: res.desc, type: 'error' })
        }
      })
    },
    // 重置
    onResetting() {
      this.form = {}
      this.timeSearch1 = []
      this.timeSearch2 = []
      this.getList()
    },
    onScreen() {
      this.currentPage = 1
      this.getList()
    },
    // 导出
    onExport() {
      if (this.onExportBtn) return
      let data = {
        pageNum: 1,
        pageSize: 9999,
        billNo: this.$route.query.billNo,
        ...this.form
      }
      if (this.timeSearch1 != null && this.timeSearch1.length > 0) {
        data.createStartTime = this.timeSearch1[0]
        data.createEndTime = this.timeSearch1[1]
      }
      if (this.timeSearch2 != null && this.timeSearch2.length > 0) {
        data.receiveStartTime = this.timeSearch2[0]
        data.receiveEndTime = this.timeSearch2[1]
      }
      this.onExportBtn = true
      exportBillInfo(data).then( () => {
          this.onExportBtn = false
      }).catch( res => {
          this.onExportBtn = false
          this.$message.error(res.desc)
      })
    },
    // 文字显示
    onText(value, arr) {
      const obj = arr.find(
        (item) => String(item.value) === String(value)
      )
      return (obj && obj.label) || '暂无数据'
    },
    // 当前页
    handleCurrentChange(val) {
      this.currentPage = val
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.myBill-page {
  .el-form-item {
    margin-bottom: 5px !important;
  }
  .el-form {
    display: flex;
    margin-left: 20px;
    flex-wrap : wrap;
  }
  .button {
    display: flex;
    align-items: center;
    padding-bottom: 5px !important;
    div {
      border-radius: 8px;
      border: 1px solid #000;
      text-align: center;
      width: 80px;
      height: 25px;
      line-height: 25px;
      cursor: pointer;
      margin-left: 10px;
    }
    .search-btn {
      border-color: #cd9f49;
      background-color: #cd9f49;
      color: #fff;
    }
    .search-btn2 {
      border-color: #bfbfc3;
      background-color: #fff;
      color: #2f3031;
    }
  }
  .breadcrumb-box {
    margin-top: 30px;
    padding-bottom: 20px;
    .el-breadcrumb {
      font-size: 18px;
    }
  }
  .content-box {
    display: flex;
    .box-rights {
      width: 1150px !important;
      border: 1px solid #d7d7d7;
      border-radius: 10px;
      .box-right-top {
        height: 40px;
        line-height: 40px;
        padding-left: 40px;
        background: #d7d7d7;
        border-bottom: 1px solid #d7d7d7;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-weight: bold;
        margin-bottom: 10px;
      }
      .table-box {
        padding: 5px 0 20px;
        .tablePage {
          display: flex;
          justify-content: flex-end;
          padding: 25px;
        }
      }
    }
  }
}
// 分页样式
::v-deep .el-pagination.is-background .el-pager {
  .active {
    background-color: #cd9f49 !important;
  }
  li:hover {
    color: #cd9f49 !important;
  }
}
</style>